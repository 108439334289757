const languages: { [key: string]: string }  = {
  ar: 'عربي',
  cs: 'Česky',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fi: 'Suomalainen',
  fr: 'Français',
  he: 'עִבְרִית',
  it: 'Italiano',
  nl: 'Nederlands',
  pt: 'Português',
  pl: 'Polski',
  ro: 'Românesc',
  sl: 'Slovenščina',
  sv: 'Svenska',
  ru: 'русский',
  zh: "普通话"
}

const country_iso2: { [key: string]: string }  = {
  ar: 'sa',
  cs: 'cz',
  bg: 'bg',
  da: 'dk',
  de: 'de',
  et: 'ee',
  el: 'gr',
  en: 'gb',
  es: 'es',
  latam: 'mx',
  fr: 'fr',
  hu: 'hu',
  he: 'il',
  ga: 'ie',
  id: 'id',
  ko: 'kr',
  it: 'it',
  is: 'is',
  hr: 'hr',
  hi: 'in',
  nl: 'nl',
  no: 'no',
  my: 'my',
  fi: 'fi',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  sl: 'sl',
  sv: 'sv',
  sk: 'sk',
  th: 'th',
  ru: 'ru',
  ms: 'my',
  tr: 'tr',
  zh: 'cn',
  ja: 'jp',
  vi: 'vn',
  uk: 'ua'
}

interface Language {
  iso     : string
  in_url  : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  language.country = country_iso2[language.country]

  return language
}

export { init_language, Language, languages, country_iso2 }

