
import { config } from '@/conf'
import { country_iso2, languages } from '@/utils/language'

export default {
  name: 'LangSelectModal',
  setup () {

    const flag = (l: string) => {
      let flag = country_iso2[l]
      return `https://hatscripts.github.io/circle-flags/flags/${flag}.svg`
    }

    return {
      config, languages, flag
    }
  }

}
